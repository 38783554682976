import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./HappeningOverviewPage.module.scss";
import classNames from "classnames";
import HappeningListContainer from "../../components/HappeningList/HappeningListContainer";
import HappeningDetailModalContainer from "../../components/HappeningDetailModal/HappeningDetailModalContainer";
import HappeningListFilter from "../../components/HappeningListFilter/HappeningListFilterContainer";
import { useIsIframe, useMap, useModalHappeningId, useShare, useShowFilters } from "../../hooks/useRouterHooks";
import HappeningMapContainer from "../../components/PerformanceMap/HappeningMapContainer";
import { lazyBeforeLoad } from "../../utils/Workarounds";

type Props = {
    className: string;
};

const focusFilters = () => {
    document.getElementById("date-picker")?.focus();
};

const HappeningOverviewPage = ({ className }: Props) => {
    const showFilters = useShowFilters();
    const isIframe = useIsIframe();
    const isShare = useShare();
    const isMap = useMap();
    const happeningId = useModalHappeningId();
    const containerRef = useRef<HTMLDivElement>(null);
    const [modalHeight, setModalHeight] = useState(0);
    const isInit = useRef(false); // Used for preventing iframe scrolling to top

    const onInit = useCallback(() => (isInit.current = true), []);

    useEffect(() => {
        lazyBeforeLoad();
    }, []);

    useEffect(() => {
        if (!containerRef.current || !isIframe) {
            return;
        }

        const resizeAction = () => {
            const isDetailOpen = !!happeningId;
            const clientHeight = containerRef.current?.clientHeight || 300;
            window.parent.postMessage(
                {
                    height: Math.max(clientHeight, isDetailOpen ? modalHeight : 0),
                    details: !!happeningId,
                    isInit: isInit.current,
                },
                "*",
            );
        };

        resizeAction();

        const resizeObserver = new ResizeObserver(resizeAction);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [happeningId, isIframe, modalHeight]);

    const onModalResize = useCallback((height: number) => {
        // Picked 125 because it worked on all resizings.
        setModalHeight(height + 125);
    }, []);

    return (
        <>
            {isMap ? (
                <>
                    <div ref={containerRef} className={classNames(styles.container, isMap && styles.map, className)}>
                        {showFilters && <HappeningListFilter className={styles.filters} />}
                    </div>
                    <HappeningMapContainer />
                </>
            ) : (
                <div ref={containerRef} className={classNames(styles.container, className)}>
                    {showFilters && <HappeningListFilter className={styles.filters} />}
                    <HappeningListContainer
                        className={classNames(styles.list)}
                        focusFilters={focusFilters}
                        onInit={onInit}
                        showBackButton={!isShare}
                    />
                </div>
            )}
            <HappeningDetailModalContainer fillPage={!isIframe} onResize={onModalResize} />
        </>
    );
};

HappeningOverviewPage.defaultProps = {
    className: "",
};

export default HappeningOverviewPage;
